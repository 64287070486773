<template>
  <section>
    <div class="additional-site-grid-header mb-3">
      <confirmation ref="Confirmation"></confirmation>
      <h1 class="bold-header-text app-header">Address</h1>
      <button class="base-button" @click="openCompanyAddressModal">Add New</button>
    </div>
    <p class="base-header-text">
      All addresses associated with your companies must be included on an application. Click the
      <strong>Add New</strong> button above to get started.
    </p>
    <b-alert show>
      <div>
        <strong>Billing, Mailing, and Physical </strong> address types are required for each
        location. You can enter an address one time and use the multi-select dropdown to identify
        all associated address types.
      </div>
    </b-alert>
    <b-card class="dashboard-card">
      <kendo-grid
        id="grid1"
        ref="grid"
        :sortable="true"
        :resizable="true"
        :sort="sort"
        :data-items="gridData"
        :selectable="true"
        :columns="tableColumns"
        @datastatechange="dataStateChange"
      >
        <template v-slot:Address="{ props }">
          <td class="text-center">
            <div>
              <p>
                {{ props.dataItem.addressLine1 }} <br />
                {{ props.dataItem.addressLine2 }}
              </p>
            </div>
          </td>
        </template>
        <template v-slot:myTemplate="{ props }">
          <td class="k-command-cell">
            <custom :data-item="props.dataItem" @edit="editCompany" @remove="deleteCompany" />
          </td>
        </template>
      </kendo-grid>

      <CompanyAddressModal ref="CompanyAddressModal"></CompanyAddressModal>
    </b-card>
  </section>
</template>
<script>
import { process } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-vue-grid";
//import { ContextMenu } from "@progress/kendo-layout-vue-wrapper";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import CompanyAddressMixin from "../../../mixins/CompanyAddressMixin.vue";
import Confirmation from "../../../components/Common/Confirmation.vue";
import ButtonActions from "../../../components/Common/ButtonActions.vue";
import { bus } from "../../../main";

import { mapGetters } from "vuex";
import CompanyAddressModal from "../../../components/Modals/CompanyAddressModal.vue";
export default {
  props: ["companyID"],
  components: {
    "kendo-grid": Grid,
    //"kendo-contextmenu": ContextMenu,
    CompanyAddressModal,
    Confirmation,
    custom: ButtonActions,
  },
  mixins: [CompanyMixin, CompanyAddressMixin],

  data() {
    return {
      tableColumns: [
        { cell: "myTemplate", title: "Actions", width: "100" },
        {
          field: "addressLine1",
          title: "Address",
          cell: "Address",
          width: "auto",
        },
        {
          field: "city",
          title: "City",
          width: "110",
        },
        {
          field: "stateCode",
          title: "State",
          width: "70",
        },
        {
          field: "zip",
          title: "Zip",
          width: "90",
        },
        {
          field: "addressTypes",
          title: "Address Types",
          width: "150",
        },
      ],
      gridData: [],
      sort: [],
      selectedAddress: null,
    };
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    await this.CompanyAddresses(this.companyID);
    this.getData(this.companyAddressTable);
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  methods: {
    getData(data) {
      this.gridData = process(data, {
        sort: this.sort,
      });
    },
    openCompanyAddressModal() {
      this.$refs.CompanyAddressModal.show({
        title: `Add New Company Address`,
        //companyAddressID: "12345",
        companyID: this.companyID,
        exitFunction: async () => {
          await this.CompanyAddresses(this.companyID);
          this.getData(this.companyAddressTable);
        },
      });
    },
    editCompany(dataItem) {
      this.$refs.CompanyAddressModal.show({
        title: `Edit Company Address for - ${dataItem.companyAddressID} `,
        companyAddressID: dataItem.companyAddressID,
        companyID: this.companyID,
        exitFunction: async () => {
          await this.CompanyAddresses(this.companyID);
          this.getData(this.companyAddressTable);
        },
      });
    },
    deleteCompany(dataItem) {
      this.$refs.Confirmation.show({
        title: "Delete Company Address",
        message: `Are you sure you want to delete ${dataItem.addressLine1}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          await this.deleteCompanyAddress(dataItem.companyAddressID).then(async () => {
            await this.CompanyAddresses(this.companyID);
            this.getData(this.companyAddressTable);
          });
        }
      });
    },
    async createAppState(dataState) {
      await this.CompanyAddresses(this.companyID);
      this.sort = dataState.sort;
      this.getData(this.companyAddressTable);
    },
    async dataStateChange(event) {
      await this.createAppState(event.data);
    },
    addNewAddress() {
      this.$router.push({ name: "Dashboard" });
    },
  },
  computed: {
    ...mapGetters({
      getCompanyAddress: "getCompanyAddress",
    }),
    companyAddressTable() {
      return this.getCompanyAddress;
    },
  },
};
</script>
